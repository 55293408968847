import request from '@/utils/request'

const userconsultsubApi = {
  // 查询列表
  getList: params => request({
    url: '/userconsultsub/list',
    method: 'get',
    params
  }), 
  // 查询详细
  getDetail: id => request({
      url: '/userconsultsub/' + id,
      method: 'get'
  }),
  // 添加
  add: data => request({
      url: '/userconsultsub',
      method: 'post',
      data
  }),

  // 修改
  update: data => request({
    url: '/userconsultsub',
    method: 'put',
    data
  }), 

  // 删除
  delete: id => request({
    url: '/userconsultsub/' + id,
    method: 'delete'
  })
} 
export default userconsultsubApi;