<template>
  <div>
    <el-form
      class="detail-form-content"
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="80px"
    >
      <!-- <el-form-item label="用户id" prop="userId">
        <el-input v-model="ruleForm.userId" placeholder="用户id" clearable/>
      </el-form-item> -->
      <el-form-item label="笔记内容" prop="content">
        <el-input v-model="ruleForm.content" placeholder="笔记内容" clearable/>
      </el-form-item>

      <el-form-item label="译文" prop="feedback">
        <el-input v-model="ruleForm.feedback" type="textarea" rows="5" placeholder="译文" clearable/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import userCustomiApi from '@/api/userCustomi.js'
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        content: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
        // feedback: [
        //   { required: true, message: "不能为空", trigger: "blur" }
        // ],
      }
    };
  },
  props: ["parent"],
  methods: {
    // 初始化
    init(id) {
      userCustomiApi.getDetail(id).then(res => {
        this.ruleForm = res.data;
      });
    },
    // 提交
    onSubmit() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if(this.ruleForm.id){
            userCustomiApi.update(this.ruleForm).then(res => {
              this.$message.success("保存成功"); 
              this.parent.showFlag = false;
              this.parent.search(); 
            });
          }else{
            userCustomiApi.add(this.ruleForm).then(res => {
              this.$message.success("提交成功"); 
              this.parent.showFlag = false;
              this.parent.search(); 
            });
          } 
        }
      });
    },
    // 返回
    back() {
      this.parent.showFlag = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.editor {
  height: 500px;
}
</style>
