<template>
  <div>
    <el-form
      class="detail-form-content"
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item label="文章内容" prop="content">
        <textarea rows="10" cols="60" v-model="ruleForm.content" placeholder="文章内容" clearable/>
      </el-form-item>
      <!-- <el-form-item label="图片" prop="picUrl">
        <ImageUpload1 v-model="ruleForm.picUrl"/>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import bbsApi from '@/api/bbs.js'
// import ImageUpload1 from '@/components/ImageUpload1/index'
export default {
  // components:{
  //   ImageUpload1
  // },
  data() {
    return {
      ruleForm: {},
      rules: {
        content: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
      }
    };
  },
  props: ["parent"],
  methods: {
    // 初始化
    init(id) {
      bbsApi.getDetail(id).then(res => {
        this.ruleForm = res.data;
      });
    },
    // 提交
    onSubmit() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if(this.ruleForm.id){
            bbsApi.updates(this.ruleForm).then(res => {
              this.$message.success("操作成功");
              this.parent.showFlag = false; 
              this.parent.search(); 
            });
          }else{
            bbsApi.add(this.ruleForm).then(res => {
              this.$message.success("操作成功");
              this.parent.showFlag = false; 
              this.parent.search(); 
            });
          } 
        }
      });
    },
    // 返回
    back() {
      this.parent.showFlag = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.editor {
  height: 500px;
}
</style>
