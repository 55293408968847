<template>
  <div>
    <img class="bg" src="@/assets/img/bg.jpg">
    <el-form :model="rulesForm" :rules="rules" ref="rulesForm" class="login-form">
      <h1 class="h1">登录</h1>
      <el-form-item label="账号" prop="account">
        <el-input type="text" v-model="rulesForm.account"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="rulesForm.password"></el-input>
      </el-form-item>
      <!-- <el-form-item label="" prop="role">
        <el-radio v-for="item in menus"  v-bind:key="item.roleName" v-model="rulesForm.role" :label="item.roleName">{{item.roleName}}</el-radio>
      </el-form-item> -->
 
      <el-button @click="login()" class="btn-login" type="primary">登录</el-button>
    </el-form>
  </div>
</template>
<script>
import userApi from '@/api/user.js'
export default {
  data() {
    return {
      rulesForm: {
        account: "admin",
        password: "123456",
        role: "1"
      }, 
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      }
    };
  },
  mounted(){
  },
  methods: {
    // 登陆
    login() {
      this.$refs["rulesForm"].validate(valid => {
        if (valid) {
          console.log(userApi,'userApi')
          userApi.login(this.rulesForm).then(res => {
            console.log(res)
            this.$storage.set("id",res.data.id)
            this.$storage.set("adminName", res.data.account);
            this.$storage.set("Token", res.data.account);
            this.$router.replace({ path: "/index/" });
          }) 
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
