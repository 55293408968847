import request from '@/utils/request'

const bbsCommentApi = {
  // 查询列表
  getList: params => request({
    url: '/bbsComment/list',
    method: 'get',
    params
  }), 
  // 查询详细
  getDetail: id => request({
      url: '/bbsComment/' + id,
      method: 'get'
  }),
  // 添加
  add: data => request({
      url: '/bbsComment',
      method: 'post',
      data
  }),

  // 修改用户
  update: data => request({
    url: '/bbsComment',
    method: 'put',
    data
  }), 

  // 删除用户
  delete: id => request({
    url: '/bbsComment/' + id,
    method: 'delete'
  })
} 
export default bbsCommentApi;