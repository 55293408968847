import Vue from 'vue';
//配置路由
import VueRouter from 'vue-router'
Vue.use(VueRouter);
//1.创建组件
import Index from '@/views/index'
import Home from '@/views/home'
import Login from '@/views/login'
import NotFound from '@/views/404'
import UpdatePassword from '@/views/update-password'
import center from '@/views/center'
import users from '@/views/modules/users/list'
import bbs from '@/views/modules/bbs/list'
import bbsComment from '@/views/modules/bbsComment/list'
import drug from '@/views/modules/drug/list'
import userCustomi from '@/views/modules/userCustomi/list'
// import consultSub from '@/views/modules/userEval/consultSub'
// import consult from '@/views/modules/userEval/consult'
//2.配置路由   注意：名字
const routes = [
    {
        path: '/index', name:'index', component: Index, children: [{
            // 这里不设置值，是把main作为默认页面
            path: '/',
            name: 'home',
            component: Home
        },{
            path: '/updatePassword',
            name: 'updatePassword',
            component: UpdatePassword
        } , {
            path: '/center',
            name: 'center',
            component: center
        },{
            path: '/users',
            name: 'users',
            component: users
        },{
            path: '/bbs',
            name: 'bbs',
            component: bbs
        } ,{
            path: '/bbsComment',
            name: 'bbsComment',
            component: bbsComment
        } ,{
            path: '/drug',
            name: 'drug',
            component: drug
        } ,{
            path: '/userCustomi',
            name: 'userCustomi',
            component: userCustomi
        } 
        ]
    },
    { path: '/login', name:'login', component: Login },
    { path: '/', redirect: '/index/' },      /*默认跳转路由*/
    { path: '*', component: NotFound }
]
//3.实例化VueRouter  注意：名字
const router = new VueRouter({
    mode: 'hash',   /*hash模式改为history*/
    routes // （缩写）相当于 routes: routes
})

export default router;

