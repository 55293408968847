<template>
  <div>
    <el-form
      class="detail-form-content"
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item label="用户名" prop="account">
        <el-input v-model="ruleForm.account" placeholder="用户名" clearable/>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name" placeholder="用户名" clearable/>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="ruleForm.password" placeholder="密码" clearable/>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="手机号" clearable/>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio v-model="ruleForm.sex" label="男">男</el-radio>
        <el-radio v-model="ruleForm.sex" label="女">女</el-radio>
      </el-form-item>
      <el-form-item label="介绍" prop="introduce">
        <el-input v-model="ruleForm.introduce" placeholder="介绍" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import userApi from '@/api/user.js'
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        account: [
          { required: true, message: "账号不能为空", trigger: "blur" }
        ],
        name: [
          { required: true, message: "姓名不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" }
        ]
      }
    };
  },
  props: ["parent"],
  methods: {
    // 初始化
    init(id) {
      userApi.getDetail(id).then(res => {
        this.ruleForm = res.data;
      });
    },
    // 提交
    onSubmit() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if(this.ruleForm.id){
            userApi.update(this.ruleForm).then(res => {
              this.$message.success("保存成功"); 
              this.parent.showFlag = false;
              this.parent.search(); 
            });
          }else{
            this.ruleForm.role = 2;
            userApi.add(this.ruleForm).then(res => {
              this.$message.success("保存成功"); 
              this.parent.showFlag = false;
              this.parent.search(); 
            });
          } 
        }
      });
    },
    // 返回
    back() {
      this.parent.showFlag = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.editor {
  height: 500px;
}
</style>
