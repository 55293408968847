import request from '@/utils/request'

const bbsApi = {
  // 查询列表
  getList: params => request({
    url: '/bbs/list',
    method: 'get',
    params
  }), 
  // 查询详细
  getDetail: id => request({
      url: '/bbs/' + id,
      method: 'get'
  }),
  // 添加
  add: data => request({
      url: '/bbs',
      method: 'post',
      data
  }),

  // 修改用户
  update: data => request({
    url: '/bbs',
    method: 'put',
    data
  }), 
  updates:data=>request({
    url: '/bbs/update',
    method: 'put',
    data
  }),
  // 删除用户
  delete: id => request({
    url: '/bbs/' + id,
    method: 'delete'
  })
} 
export default bbsApi;