const base = {
    get() {
                return {
            url : "http://localhost:8080/springboot0CXty/",
            name: "springboot0CXty",
            // 退出到首页链接
            indexUrl: 'http://localhost:8080/springboot0CXty/front/index.html'
        }
            },
    getProjectName(){
        return {
            projectName: "后台管理系统"
        } 
    }
}
export default base