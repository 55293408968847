import request from '@/utils/request'

const userApi = {
  // 查询用户列表
  getList: params => request({
    url: '/user/list',
    method: 'get',
    params
  }), 
  // 查询用户详细
  getDetail: id => request({
      url: '/user/info/' + id,
      method: 'get'
  }),

  // 登录
  login: data => request({
    url: '/user/login',
    method: 'post',
    data: data
  }),
  // 添加用户
  add: data => request({
      url: '/user/save',
      method: 'post',
      data
  }),

  // 修改用户
  update: data => request({
    url: '/user/update',
    method: 'put',
    data
  }), 

  // 删除用户
  delete: id => request({
    url: '/user/' + id,
    method: 'delete'
  })
} 
export default userApi;