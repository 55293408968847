<template>
  <div class="app-container home">
    <!-- <img src="../assets/images/homebg1.jpeg" style="width:100%;height:100%;" /> -->
     <div class="home-text">欢迎登录后台管理系统</div>
     
  </div>
</template>
<script>
import router from '@/router/router-static'
export default {
  data() {
    return {
      JobTotal:'',
      CompanyTotal:'',
      DeliverTotal:'',
      womanDeliver:'',
      manDeliver:''
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.home{
  width: 100%;
  height: 93%;
}
.box-card{
  height: 100%;
}
.box-count{
  font-size: 28px;
  font-weight: bolder;
  color: #fff;
  margin-top: 20px;
}
.box-title{
  font-size: 14px;
  color: #fff;
}
.card{
  font-size: 28px;font-weight: bold; 
  height: 130px;
}
.card1{
  background: linear-gradient(to right, #566ef1, blue);
}
.card2{
  background: linear-gradient(to right, #53c1f2, #03A9F4);
}
.card3{
  background: linear-gradient(to right, #c762d8, #9C27B0);
}
.card4{
  background: linear-gradient(to right, #38bdb1, #009688);
}
.card5{
  background: linear-gradient(to right, #ec8e70, #FF5722);
}
.f14{
  font-size: 14px;
}

</style>